<template>
    <div class="page">
        <img src="./../../assets/images/management/login_bg.png" class="bg" alt="">
        
        <div class="form">
            <div class="form-item">
                <div class="icon">
                    <img src="./../../assets/images/management/user.png" alt="">
                </div>
                <div class="inputBox">
                    <input type="number" placeholder="请输入账号" v-model="userName">
                </div>
            </div>
            <div class="form-item">
                <div class="icon">
                    <img src="./../../assets/images/management/pwd.png" alt="">
                </div>
                <div class="inputBox">
                    <input :type="!showPassword ? 'password' : 'text'" placeholder="请输入密码" v-model="passWrod">
                </div>
     
                <img v-if="showPassword" src="./../../assets/images/management/eye.png" @click="showPassword = !showPassword" class="eye" alt="">
                <img v-else src="./../../assets/images/management/eye_close.png" @click="showPassword = !showPassword" class="eye" alt="">
            </div>
            <div class="button" @click="submit">登录</div>
        </div>
    </div>
</template>

<script>
export default {
    name: "login",
    data() {
        return {
            showPassword: false,
            userName: '',
            passWrod: ''
        }
    },
    
    methods: {
        submit() {
            if (!this.userName) {
                this.$toast('请输入账号');
                return;
            }
            
            if (this.userName.length !== 4) {
                this.$toast('请输入4位数的账号');
                return;
            }
            if (!this.passWrod) {
                this.$toast('请输入密码');
                return;
            }
    
            if (this.passWrod.length !== 10) {
                this.$toast('请输入10位数的密码');
                return;
            }
            
            this.$toast.success('登录成功')
            
            setTimeout(() => {
                this.$router.replace('/management/statistical')
            }, 1500)
        }
    }
}
</script>

<style scoped lang="less">
.page {
    height: 100vh;
    overflow: hidden;
    background: linear-gradient(0deg, #FCFBFC 0%, #F4F7FC 100%);
    position: relative;
    * {
        box-sizing: border-box;
    }
    .bg {
        height: 313px;
        position: absolute;
        left: -50px;
        top: -66px;
    }
    .form {
        position: relative;
        width: 340px;
        height: 267px;
        background: #FFFFFF;
        border-radius: 16px;
        box-shadow: 0 0 10px 10px rgba(0,0,0,.02);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0 33px;
        margin: 183px 18px 0;
        .form-item {
            padding: 19px 0;
            display: flex;
            align-items: center;
            width: 100%;
            box-sizing: border-box;
            &:first-child {
                border-bottom: 1px solid rgba(132, 132, 132, .2);
            }
            .icon {
                height: 9px;
                width: 23px;
                display: flex;
                align-items: center;
                position: relative;
                //border-right: 1px solid rgba(0, 0, 0, .2);
                > img {
                    height: 13px;
                    width: auo;
                }
            }
            
            .inputBox {
                flex: 1;
                padding: 0 21px;
                input {
                   
                    background-color: transparent !important;
                    border: none;
                   
                    font-size: 13px;
                    box-sizing: border-box;
                }
            }
            .eye { height: 12px; }
        }
    
        .button {
            width: 274px;
            height: 43px;
            background: linear-gradient(-45deg, #FF553F 0%, #FF553F 100%);
            box-shadow: 0px 3px 5px 0px rgba(255,125,108,0.2800);
            border-radius: 8px;
    
            font-size: 18px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #FFFFFF;
            
            line-height: 43px;
            text-align: center;
            
            margin-top: 15px;
        }
    }
}
</style>
